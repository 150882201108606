import  { useState, useEffect, useRef } from 'react';
import { Plus, ArrowLeft } from 'lucide-react';
import { Logo } from './Logo';
import Header from './Header';
import FileUpload from './FileUpload';
import CreateCardsModal from './CreateCardsModal';
import ReferralModal from './ReferralModal';
import LeaderboardModal from './LeaderboardModal';
import AuthModal from './AuthModal';
import SearchInput from './SearchInput';
import { FlashCard, FlashCardSet } from '../types';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';
import { removeDuplicateSets, getUniqueSetName } from '../utils/setUtils';

interface SetsPageProps {
  sets: FlashCardSet[];
  onSetSelect: (setId: string) => void;
  onCardsUpload: (cards: FlashCard[], fileName: string) => void;
  onStartQuiz: () => void;
}

export default function SetsPage({ sets, onSetSelect, onCardsUpload, onStartQuiz }: SetsPageProps) {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showReferralModal, setShowReferralModal] = useState(false);
  const [showLeaderboardModal, setShowLeaderboardModal] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [editingSet, setEditingSet] = useState<FlashCardSet | null>(null);
  const [publicSets, setPublicSets] = useState<FlashCardSet[]>([]);
  const [showingPublicSets, setShowingPublicSets] = useState(false);
  const [loading, setLoading] = useState(false);
  const [importing, setImporting] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [displayedSets, setDisplayedSets] = useState<FlashCardSet[]>([]);
  const { user, profile } = useAuth();
  const [showDailyPoints, setShowDailyPoints] = useState(false);
  const [dailyPoints, setDailyPoints] = useState(0);
  const interactionAudioRef = useRef<HTMLAudioElement | null>(null);
  const [streak, setStreak] = useState<number | null>(null);
  const [isFirstActivity, setIsFirstActivity] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    // Initialize interaction sound
    interactionAudioRef.current = new Audio('/Interaction_Sound.mp3');
    interactionAudioRef.current.load();

    return () => {
      if (interactionAudioRef.current) {
        interactionAudioRef.current.pause();
        interactionAudioRef.current = null;
      }
    };
  }, []);

  const playInteractionSound = () => {
    if (interactionAudioRef.current) {
      interactionAudioRef.current.currentTime = 0;
      interactionAudioRef.current.play().catch(console.error);
    }
  };

  useEffect(() => {
    if (user) {
      const fetchDailyPoints = async () => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const { data, error } = await supabase
          .from('game_scores')
          .select('score')
          .eq('user_id', user.id)
          .gte('created_at', today.toISOString());

        if (!error && data) {
          const total = data.reduce((sum, score) => sum + score.score, 0);
          setDailyPoints(total);
        }
      };

      fetchDailyPoints();
    }
  }, [user]);

  useEffect(() => {
    if (showingPublicSets) {
      setDisplayedSets(publicSets);
    }
  }, [showingPublicSets, publicSets]);

  useEffect(() => {
    if (!showingPublicSets) return;

    const filterSets = async () => {
      try {
        setLoading(true);

        const { data: setsData, error: setsError } = await supabase
          .from('flashcard_sets')
          .select(`
            *,
            flashcards (
              word,
              definition
            )
          `)
          .eq('is_public', true)
          .order('created_at', { ascending: false });

        if (setsError) throw setsError;

        const filteredSets = setsData.filter(set => {
          const searchLower = searchQuery.toLowerCase();
          if (set.name.toLowerCase().includes(searchLower)) return true;

          const cards = set.flashcards as { word: string; definition: string }[];
          return cards.some(card =>
            card.word.toLowerCase().includes(searchLower) ||
            card.definition.toLowerCase().includes(searchLower)
          );
        });

        const transformedSets = filteredSets.map(set => ({
          id: set.id,
          name: set.name,
          createdAt: new Date(set.created_at),
          totalCards: (set.flashcards as any[]).length,
          masteredCards: 0,
          isPublic: true
        }));

        setDisplayedSets(transformedSets);
      } catch (err) {
        console.error('Error filtering sets:', err);
      } finally {
        setLoading(false);
      }
    };

    filterSets();
  }, [searchQuery, showingPublicSets]);

  const handleSearch = async () => {
    if (!showingPublicSets) return;
    
    try {
      setLoading(true);
      
      const { data: setsData, error: setsError } = await supabase
        .from('flashcard_sets')
        .select(`
          *,
          flashcards (
            word,
            definition
          )
        `)
        .eq('is_public', true)
        .order('created_at', { ascending: false });

      if (setsError) throw setsError;

      const filteredSets = setsData.filter(set => {
        const searchLower = searchQuery.toLowerCase();
        if (set.name.toLowerCase().includes(searchLower)) return true;
        
        const cards = set.flashcards as { word: string; definition: string }[];
        return cards.some(card => 
          card.word.toLowerCase().includes(searchLower) ||
          card.definition.toLowerCase().includes(searchLower)
        );
      });

      const transformedSets = filteredSets.map(set => ({
        id: set.id,
        name: set.name,
        createdAt: new Date(set.created_at),
        totalCards: (set.flashcards as any[]).length,
        masteredCards: 0,
        isPublic: true
      }));

      setDisplayedSets(transformedSets);
    } catch (err) {
      console.error('Error searching sets:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleShowAuthModal = () => {
    setShowAuthModal(true);
  };

  const handleSetSelect = async (setId: string) => {
    if (!user) return;

    console.log('Selected flashcard set ID:', setId); // Log the flashcard set ID

    // Save the flashcard set ID to local storage
    localStorage.setItem('selectedFlashcardSetId', setId);

    const { error: streakError } = await supabase
      .rpc('handle_user_activity', { user_id: user.id });

    if (streakError) {
      console.error('Error updating activity streak:', streakError);
    }
    
    onSetSelect(setId);
  };

  const handleImportSet = async (setId: string) => {
    if (!user) {
      handleShowAuthModal();
      return;
    }

    try {
      console.log('Starting import for set ID:', setId); 
      setImporting(setId);
      setError(null);

      // Get the original set and its cards
      const { data: originalSet, error: fetchError } = await supabase
        .from('flashcard_sets')
        .select(`
          *,
          flashcards (
            word,
            definition
          )
        `)
        .eq('id', setId)
        .single();

      if (fetchError) {
        console.error('Error fetching original set:', fetchError);
        setError('Failed to fetch flash card set');
        return;
      }
      
      if (!originalSet || !originalSet.flashcards) {
        throw new Error('Flash card set not found or contains no cards');
      }

      console.log('Original flashcard set:', originalSet); // Log original flashcard set

      const cards = originalSet.flashcards.map(card => ({
        word: card.word,
        definition: card.definition
      }));

      if (cards.length === 0) {
        throw new Error('No cards found in the set');
      }

      console.log('Number of cards to import:', cards.length); // Log the number of cards

      // Create new set with original name
      const { data: newSet, error: newSetError } = await supabase
        .from('flashcard_sets')
        .insert({
          name: originalSet.name,
          created_by: user.id,
          is_public: true
        })
        .select()
        .single();

      if (newSetError) {
        console.error('Error creating new set:', newSetError);
        throw new Error('Failed to create new flash card set');
      }

      console.log('New flashcard set created:', newSet); // Log new flashcard set

      // Insert cards into the new set
      const { error: cardsError } = await supabase
        .from('flashcards')
        .insert(
          cards.map(card => ({
            set_id: newSet.id,
            word: card.word,
            definition: card.definition
          }))
        );

      if (cardsError) { 
        // Cleanup if card insertion fails
        await supabase
          .from('flashcard_sets')
          .delete()
          .eq('id', newSet.id);
        
        console.error('Error inserting cards:', cardsError);
        throw new Error('Failed to add cards to the set');
      }

      console.log('Imported flashcard set:', newSet); // Log imported flashcard set

      window.location.reload();
    } catch (err) {
      console.error('Error importing set:', err);
      const message = err instanceof Error ? err.message : 'Failed to import set';
      alert(message);
    } finally {
      console.log('Import process completed for set ID:', setId); // Log the completion of the import process
      setImporting(null);
    }
  };

  useEffect(() => {
    const fetchPublicSets = async () => {
      if (!showingPublicSets) return;
      
      try {
        setLoading(true);
        
        const { data: setsData, error: setsError } = await supabase
          .from('flashcard_sets')
          .select(`
            *,
            flashcards (*)
          `)
          .eq('is_public', true)
          .order('created_at', { ascending: false });

        if (setsError) throw setsError;

        console.log('Fetched public flashcard sets:', setsData); // Log fetched flashcard sets

        const transformedSets = setsData.map(set => ({
          id: set.id,
          name: set.name,
          createdAt: new Date(set.created_at),
          totalCards: (set.flashcards as any[]).length,
          masteredCards: 0,
          isPublic: true
        }));

        setPublicSets(transformedSets);
        setDisplayedSets(transformedSets);
      } catch (err) {
        console.error('Error fetching public sets:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchPublicSets();
  }, [showingPublicSets]);

  useEffect(() => {
    const handleActivity = async () => {
      if (!user) {
        console.log('No user logged in, skipping activity handling.');
        return;
      }

      console.log('Fetching and updating user activity streak...');
      try {
        // Fetch current streak and last_updated date
        const { data: userData, error } = await supabase
          .from('profiles')
          .select('activity_streak, last_updated')
          .eq('id', user.id)
          .single();

        if (error) {
          console.error('Failed to fetch user streak:', error);
          setError('Failed to update streak');
          return;
        }

        const today = new Date().toISOString().split('T')[0]; // Format as YYYY-MM-DD
        const lastUpdated = userData?.last_updated;

        let newStreak = userData?.activity_streak || 0;

        if (lastUpdated !== today) {
          // Increment streak only if it's a new day
          newStreak += 1;

          // Update streak and last_updated in Supabase
          const { error: updateError } = await supabase
            .from('profiles')
            .update({
              activity_streak: newStreak,
              last_updated: today,
            })
            .eq('id', user.id);

          if (updateError) throw updateError;

          console.log(`Streak updated: ${newStreak} days.`);
          setStreak(newStreak);
          setIsFirstActivity(true);
        } else {
          console.log('Streak already updated today.');
          setStreak(newStreak);
          setIsFirstActivity(false);
        }
      } catch (err) {
        console.error('Unexpected error while updating streak:', err);
        setError('An error occurred while updating your streak.');
      }
    };

    handleActivity();
  }, [user]);
  
  

  const LoggedInView = () => (
    <div className="container mx-auto px-4 py-12 max-w-4xl">
      <div className="flex flex-col items-center gap-12">
        <Header />

        <div className="text-center space-y-4">
          {error && <div className="text-red-500">{error}</div>}
          <div className="text-highlight font-luckiest text-5xl ">
            {streak ? `${streak} Day Streak! 🔥` : 'Welcome Back!'}
          </div>
          <div 
            className="text-highlight font-luckiest text-4xl cursor-pointer hover:scale-105 transition-transform"
            onClick={() => {
              playInteractionSound();
              setShowDailyPoints(!showDailyPoints);
            }}
          >
            {showDailyPoints ? `Daily Points: ${dailyPoints}` : `Total Points: ${profile?.totalPoints || 0}`}
          </div>
        </div>

        <div className="flex items-center gap-8 text-4xl mt-6">
          <button 
            onClick={() => {
              playInteractionSound();
              setShowReferralModal(true);
            }}
            className="hover:scale-110 transition-transform"
          >
            🤩
          </button>
          <button 
            onClick={() => {
              playInteractionSound();
              setShowLeaderboardModal(true);
            }}
            className="hover:scale-110 transition-transform"
          >
            🏆
          </button>
        </div>
      </div>
      
      <h1 className="text-6xl font-bold text-center text-white mb-12 mt-8">Flash Cards</h1>
      
      <div className="flex flex-col items-center mb-16 space-y-4">
        <div className="flex justify-center w-full max-w-4xl space-x-4">
          <button
            onClick={() => setShowingPublicSets(!showingPublicSets)}
            className={`button-3d py-2 px-2 md:px-8 w-1/3 text-xs md:text-base ${showingPublicSets ? 'bg-accent text-white' : ''}`}
          >
            Public Flash Cards
          </button>
          

          <label
            htmlFor="file-upload"
            className="button-3d py-2 px-2 md:px-8 w-1/3 text-center text-xs md:text-base"
          >
            Upload CSV
          </label>

          <button
            onClick={() => {
              setEditingSet(null);
              setShowCreateModal(true);
            }}
            className="button-3d py-2 px-2 md:px-8 w-1/3 flex items-center justify-center gap-2 text-xs md:text-base"
          >
            <Plus className="w-5 h-5" />
            Create New Cards
          </button>
        </div>
      </div>
      
      {showingPublicSets ? (
        <div className="mb-12">
          <div className="flex justify-between items-center mb-8">
            <button
              onClick={() => setShowingPublicSets(false)}
              className="button-3d flex items-center gap-2"
            >
              <ArrowLeft className="w-5 h-5" />
              Exit
            </button>
            <h2 className="text-4xl font-bold text-white">Public Flash Card Sets</h2>
          </div>

          <div className="mb-6">
            <SearchInput
              value={searchQuery}
              onChange={(value) => setSearchQuery(value)}
              onSearch={handleSearch}
              placeholder="Search sets by title or content..."
            />
          </div>

          {loading ? (
            <div className="text-white text-center">Loading public sets...</div>
          ) : (
            <div className="space-y-4">
              {displayedSets.map(set => {
                return (
                  <div key={set.id} className="card-set block w-full text-left">
                    <h3 className="text-2xl font-bold mb-2">{set.name}</h3>
                    <div className="flex justify-between items-center mt-2">
                      <span className="text-white/60">{set.totalCards} cards</span>
                      <button
                        onClick={() => handleImportSet(set.id)}
                        className="button-3d py-2 px-4"
                        disabled={importing === set.id}
                      >
                        {importing === set.id ? 'Importing...' : 'Import'}
                      </button>
                    </div>
                  </div>
                );
              })}
              {displayedSets.length === 0 && (
                <p className="text-white text-center">No matching sets found.</p>
              )}
            </div>
          )}
        </div>
      ) : sets.length > 0 && (
        <div className="mb-12">
          <h2 className="text-4xl font-bold text-white mb-8">Your Flash Card Sets</h2>
          <div className="space-y-4">
            {sets
              .filter((set, index, self) => 
                index === self.findIndex((s) => s.id === set.id)
              )
              .map(set => (
              <div 
                key={set.id} 
                className={`p-6 rounded-[2px] w-full text-left ${
                  set.name === "😄Today's Review⭐" 
                    ? 'bg-highlight text-black shadow-brutal' 
                    : 'card-set'
                }`}
              >
                <div className="flex justify-between items-start mb-2">
                  <h3 className="text-2xl font-bold">{set.name}</h3>
                  <button
                    onClick={() => {
                      setEditingSet(set);
                      setShowCreateModal(true);
                    }}
                    className={set.name === "😄Today's Review⭐" ? 'bg-[#FF1C60] text-white shadow-brutal py-2 px-8 text-sm font-bold' : 'button-3d py-1 px-3 text-sm'}
                  >
                    Edit
                  </button>
                </div>
                <button
                  onClick={() => handleSetSelect(set.id)}
                  className="flex justify-end items-center mt-2 w-full "
                >
                  <span className="text-xl">{set.totalCards} cards →</span>
                </button>
              </div>
            ))}
          </div>
        </div>
      )}

      {!showingPublicSets && <FileUpload onCardsUpload={onCardsUpload} />}
    </div>
  );

  const PreLoginView = () => (
    <div className="min-h-screen bg-primary text-white">
      <div className="w-full flex justify-between items-center p-4">
        <Logo />
        <button
          onClick={handleShowAuthModal}
          className="button-3d"
        >
          Sign Up / Login
        </button>
      </div>

      <div className="container mx-auto px-4 py-8">
        <div className="flex flex-col lg:flex-row items-start justify-between gap-12">
          <div className="lg:w-1/2 space-y-8 lg:space-y-16 mb-8 lg:mb-0">
            <div className="space-y-8 lg:space-y-12">
              <h1 className="text-[4rem] lg:text-[5.5rem] font-bold leading-[0.9]">
                Play Time<br />equals<br />Learn Time
              </h1>
              
              <p className="text-lg font-bold">
                Learn effortlessly through games.<br />
                Make your own flash card sets, or use pre-made study plans.
              </p>
            </div>

            <div>
              <button 
                onClick={handleShowAuthModal}
                className="button-3d"
              >
                Sign Up For Free
              </button>
            </div>

            <div className="hidden lg:block">
              <div className="space-y-6">
                <div className="space-y-4">
                  <div className="flex items-start gap-4">
                    <span className="text-highlight">•</span>
                    <div>
                      <strong className="text-highlight">Faster Learning:</strong>
                      <p>Master skills up to 50% faster with immersive, interactive games.</p>
                    </div>
                  </div>

                  <div className="flex items-start gap-4">
                    <span className="text-highlight">•</span>
                    <div>
                      <strong className="text-highlight">Increase Motivation:</strong>
                      <p>Learners spend 34% more time on tasks compared to traditional methods.</p>
                    </div>
                  </div>

                  <div className="flex items-start gap-4">
                    <span className="text-highlight">•</span>
                    <div>
                      <strong className="text-highlight">Increased Focus:</strong>
                      <p>Captures and sustains attention, especially for learners with ADHD.</p>
                    </div>
                  </div>

                  <div className="flex items-start gap-4">
                    <span className="text-highlight">•</span>
                    <div>
                      <strong className="text-highlight">Skill Development:</strong>
                      <p>Helps learners with autism build social and communication skills.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="lg:w-1/2 w-full">
            <div className="relative max-w-md mx-auto">
              <div className="absolute -top-8 right-0 text-right">
                <p className="text-white/80">Score</p>
                <p className="text-highlight text-4xl font-bold">10</p>
              </div>

              <div className="bg-accent rounded-[2px] p-4 shadow-brutal aspect-[10/16] w-full">
                <div className="w-full h-full relative">
                  <div className="absolute top-[10%] left-[20%] grid grid-cols-3 gap-0.5">
                    <div className="w-6 h-6"></div>
                    <div className="w-6 h-6 bg-white shadow-inner"></div>
                    <div className="w-6 h-6"></div>
                    <div className="w-6 h-6 bg-white shadow-inner"></div>
                    <div className="w-6 h-6 bg-white shadow-inner"></div>
                    <div className="w-6 h-6 bg-white shadow-inner"></div>
                  </div>

                  <div className="absolute top-[5%] right-[30%] grid grid-cols-1 gap-0.5">
                    <div className="w-6 h-6 bg-white shadow-inner"></div>
                    <div className="w-6 h-6 bg-white shadow-inner"></div>
                    <div className="w-6 h-6 bg-white shadow-inner"></div>
                    <div className="w-6 h-6 bg-white shadow-inner"></div>
                  </div>

                  <div className="absolute top-[25%] left-[40%] grid grid-cols-2 gap-0.5">
                    <div className="w-6 h-6 bg-white shadow-inner"></div>
                    <div className="w-6 h-6 bg-white shadow-inner"></div>
                    <div className="w-6 h-6 bg-white shadow-inner"></div>
                    <div className="w-6 h-6 bg-white shadow-inner"></div>
                  </div>

                  <div className="absolute bottom-0 left-1/2 -translate-x-1/2 grid grid-cols-3 gap-0.5">
                    <div className="w-6 h-6 bg-white shadow-inner"></div>
                    <div className="w-6 h-6 bg-white shadow-inner"></div>
                    <div className="w-6 h-6 bg-white shadow-inner"></div>
                    <div className="w-6 h-6 bg-white shadow-inner"></div>
                    <div className="w-6 h-6"></div>
                    <div className="w-6 h-6"></div>
                  </div>
                </div>
              </div>

              <div className="bg-accent mt-4 p-4 rounded-[2px] shadow-brutal">
                <h3 className="text-4xl mb-4 font-bold">opulence</h3>
                <div className="grid grid-cols-2 gap-4">
                  <button className="bg-highlight text-black p-2 rounded-[2px] shadow-brutal font-extrabold">
                    ruling family
                  </button>
                  <button className="bg-highlight text-black p-2 rounded-[2px] shadow-brutal font-extrabold">
                    astonished
                  </button>
                  <button className="bg-highlight text-black p-2 rounded-[2px] shadow-brutal font-extrabold">
                    wealth
                  </button>
                  <button className="bg-highlight text-black p-2 rounded-[2px] shadow-brutal font-extrabold">
                    said no
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-12 lg:hidden">
          <div className="space-y-6">
            <div className="space-y-4">
              <div className="flex items-start gap-4">
                <span className="text-highlight">•</span>
                <div>
                  <strong className="text-highlight">Faster Learning:</strong>
                  <p>Master skills up to 50% faster with immersive, interactive games.</p>
                </div>
              </div>

              <div className="flex items-start gap-4">
                <span className="text-highlight">•</span>
                <div>
                  <strong className="text-highlight">Increase Motivation:</strong>
                  <p>Learners spend 34% more time on tasks compared to traditional methods.</p>
                </div>
              </div>

              <div className="flex items-start gap-4">
                <span className="text-highlight">•</span>
                <div>
                  <strong className="text-highlight">Increased Focus:</strong>
                  <p>Captures and sustains attention, especially for learners with ADHD.</p>
                </div>
              </div>

              <div className="flex items-start gap-4">
                <span className="text-highlight">•</span>
                <div>
                  <strong className="text-highlight">Skill Development:</strong>
                  <p>Helps learners with autism build social and communication skills.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {user ? <LoggedInView /> : <PreLoginView />}

      <CreateCardsModal
        isOpen={showCreateModal}
        onClose={() => {
          setShowCreateModal(false);
          setEditingSet(null);
        }}
        onSuccess={() => window.location.reload()}
        editingSet={editingSet}
      />

      <ReferralModal
        isOpen={showReferralModal}
        onClose={() => setShowReferralModal(false)}
      />

      <LeaderboardModal
        isOpen={showLeaderboardModal}
        onClose={() => setShowLeaderboardModal(false)}
      />

      <AuthModal
        isOpen={showAuthModal}
        onClose={() => setShowAuthModal(false)}
      />
    </>
  );
}