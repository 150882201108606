import { Word } from '../types/game';
import { supabase } from '../lib/supabase';

function shuffleArray(array: any[]) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

export const getRandomWord = async (): Promise<Word | null> => {

  const setId = localStorage.getItem('selectedFlashcardSetId');

  if (!setId) {
    console.error('No flashcard set selected.');
    return null;
  }

  const { data: setData, error: setError } = await supabase
    .from('flashcard_sets')
    .select('id')
    .eq('id', setId)
    .single();

  if (setError || !setData) {
    console.error('Flashcard set not found:', setId);
    return null;
  }

  const { data, error } = await supabase
    .from('flashcards')
    .select('*')
    .eq('set_id', setId); 

  if (error) {
    console.error('Error fetching words:', error);
    return null;
  }

  if (!data || data.length === 0) {
    console.error('No words available in the selected set:', setId);
    return null;
  }

  shuffleArray(data);

  localStorage.setItem('totalWords', data.length.toString()); 
  const randomWord = data[0]; 
  console.log('Selected random word:', randomWord);
  return randomWord;
};