import React, { useEffect, useRef, useState, useCallback } from 'react';

interface TetrisGameProps {
  isCorrect: boolean | null;
  onGameOver: () => void;
  gameOver: boolean;
  onReset?: () => void;
}

const COLS = 10;
const ROWS = 20;
const BLOCK_SIZE = 30;

const SHAPES = [
  [[1, 1, 1], [0, 1, 0]], // T-shape
  [[1, 1], [1, 1]],       // O-shape
  [[1, 1, 0], [0, 1, 1]], // Z-shape
  [[0, 1, 1], [1, 1, 0]], // S-shape
  [[1, 1, 1, 1]],         // I-shape
  [[1, 1, 1], [1, 0, 0]], // L-shape
  [[1, 1, 1], [0, 0, 1]], // J-shape
];

const SPEEDS = {
  NORMAL: 500,
  FAST: 50,     // Faster constant speed for correct answers
  FALLING: 50   // Consistent speed for wrong answers
};

export default function TetrisGame({ isCorrect, onGameOver, gameOver, onReset }: TetrisGameProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const requestRef = useRef<number>();
  const lastUpdateRef = useRef<number>(0);
  const containerRef = useRef<HTMLDivElement>(null);
  
  const [grid, setGrid] = useState<number[][]>(() => 
    Array.from({ length: ROWS }, () => Array(COLS).fill(0))
  );
  const [currentShape, setCurrentShape] = useState(() => SHAPES[Math.floor(Math.random() * SHAPES.length)]);
  const [shapeX, setShapeX] = useState(Math.floor(COLS / 2) - Math.floor(currentShape[0].length / 2));
  const [shapeY, setShapeY] = useState(0);
  const [gameSpeed, setGameSpeed] = useState(SPEEDS.NORMAL);
  const [autoArrange, setAutoArrange] = useState(false);
  const [targetPosition, setTargetPosition] = useState<{ x: number, y: number, shape: number[][] } | null>(null);
  const [fastFall, setFastFall] = useState(false);
  const [movementQueue, setMovementQueue] = useState<Array<{ type: 'move' | 'rotate', value?: number }>>([]);
  const [shouldUpdate, setShouldUpdate] = useState(true);

  // New states for tracking game duration
  const [startTime, setStartTime] = useState<number | null>(null);
  const [duration, setDuration] = useState<number | null>(null);

  // We introduce a local state for isCorrect to allow resetting it after a delay without affecting props
  const [localIsCorrect, setLocalIsCorrect] = useState(isCorrect);

  useEffect(() => {
    // Whenever prop isCorrect changes, update localIsCorrect
    setLocalIsCorrect(isCorrect);
  }, [isCorrect]);

  const resetGame = useCallback(() => {
    if (!onReset) return;
    
    setGrid(Array.from({ length: ROWS }, () => Array(COLS).fill(0)));
    setCurrentShape(SHAPES[Math.floor(Math.random() * SHAPES.length)]);
    setShapeX(Math.floor(COLS / 2));
    setShapeY(0);
    setGameSpeed(SPEEDS.NORMAL);
    setAutoArrange(false);
    setTargetPosition(null);
    setFastFall(false);
    setMovementQueue([]);
    setShouldUpdate(true);
    onReset();
  }, [onReset]);

  useEffect(() => {
    if (gameOver) {
      setShouldUpdate(false);
    }
  }, [gameOver]);

  // Track the start time when the game begins and the duration when the game ends
  useEffect(() => {
    if (!gameOver && startTime === null) {
      setStartTime(Date.now());
    } else if (gameOver && startTime !== null) {
      setDuration(Date.now() - startTime);
    }
  }, [gameOver, startTime]);

  useEffect(() => {
    if (duration !== null) {
      console.log(`Game duration: ${duration / 1000} seconds`);
    }
  }, [duration]);

  const updateCanvasSize = useCallback(() => {
    if (!containerRef.current || !canvasRef.current) return;
    
    const container = containerRef.current;
    const canvas = canvasRef.current;
    
    const containerWidth = container.clientWidth;
    const containerHeight = container.clientHeight;
    
    const gameAspectRatio = COLS / ROWS;
    const containerAspectRatio = containerWidth / containerHeight;
    
    let newScale;
    if (containerAspectRatio > gameAspectRatio) {
      newScale = containerHeight / (ROWS * BLOCK_SIZE);
    } else {
      newScale = containerWidth / (COLS * BLOCK_SIZE);
    }
    
    canvas.style.transform = `scale(${newScale})`;
    canvas.style.transformOrigin = 'center center';
  }, []);

  const rotate = useCallback((shape: number[][], times: number = 1): number[][] => {
    let rotated = [...shape];
    for (let i = 0; i < times; i++) {
      const rows = rotated.length;
      const cols = rotated[0].length;
      const newRotated = Array.from({ length: cols }, () => Array(rows).fill(0));
      
      for (let r = 0; r < rows; r++) {
        for (let c = 0; c < cols; c++) {
          newRotated[c][rows - 1 - r] = rotated[r][c];
        }
      }
      rotated = newRotated;
    }
    return rotated;
  }, []);

  const collide = useCallback((shape: number[][], x: number, y: number): boolean => {
    for (let row = 0; row < shape.length; row++) {
      for (let col = 0; col < shape[row].length; col++) {
        if (shape[row][col]) {
          const newY = y + row;
          const newX = x + col;
          if (newX < 0 || newX >= COLS || newY >= ROWS) {
            return true;
          }
          if (newY >= 0 && grid[newY][newX]) {
            return true;
          }
        }
      }
    }
    return false;
  }, [grid]);

  const calculateHoles = useCallback((tempGrid: number[][]): number => {
    let holes = 0;
    for (let col = 0; col < COLS; col++) {
      let blockFound = false;
      for (let row = 0; row < ROWS; row++) {
        if (tempGrid[row][col] === 1) {
          blockFound = true;
        } else if (blockFound && tempGrid[row][col] === 0) {
          holes++;
        }
      }
    }
    return holes;
  }, []);

  const findBestPosition = useCallback(() => {
    let bestScore = -Infinity;
    let bestX = shapeX;
    let bestY = 0;
    let bestShape = currentShape;
    let bestRotation = 0;

    // Try all rotations
    for (let rotation = 0; rotation < 4; rotation++) {
      const rotatedShape = rotate(currentShape, rotation);
      
      // Try all horizontal positions
      for (let x = -2; x < COLS + 2; x++) {
        let y = 0;
        // Drop piece to bottom
        while (!collide(rotatedShape, x, y + 1)) {
          y++;
        }

        if (!collide(rotatedShape, x, y)) {
          // Create temporary grid with piece placed
          const tempGrid = grid.map(row => [...row]);
          for (let row = 0; row < rotatedShape.length; row++) {
            for (let col = 0; col < rotatedShape[row].length; col++) {
              if (rotatedShape[row][col] && y + row >= 0 && y + row < ROWS && x + col >= 0 && x + col < COLS) {
                tempGrid[y + row][x + col] = 1;
              }
            }
          }

          // Calculate score based on multiple factors
          let completedLines = 0;
          for (let row = ROWS - 1; row >= 0; row--) {
            if (tempGrid[row].every(cell => cell === 1)) {
              completedLines++;
            }
          }

          const holes = calculateHoles(tempGrid);
          const height = ROWS - y;
          const distanceFromCenter = Math.abs(x - COLS / 2);

          // Scoring system
          const score = 
            completedLines * 100 +    // Prioritize completing lines
            -holes * 30 +            // Heavily penalize creating holes
            -height * 2 +            // Slight penalty for height
            -distanceFromCenter * 2;  // Slight penalty for being far from center

          if (score > bestScore) {
            bestScore = score;
            bestX = x;
            bestY = y;
            bestShape = rotatedShape;
            bestRotation = rotation;
          }
        }
      }
    }

    // Generate movement queue
    const newQueue: Array<{ type: 'move' | 'rotate', value?: number }> = [];
    
    // First add rotations
    if (bestRotation > 0) {
      newQueue.push({ type: 'rotate', value: bestRotation });
    }

    // Then add horizontal movements
    const moveX = bestX - shapeX;
    if (moveX !== 0) {
      newQueue.push({ type: 'move', value: moveX });
    }

    setMovementQueue(newQueue);

    return { x: bestX, y: bestY, shape: bestShape };
  }, [currentShape, grid, shapeX, rotate, collide, calculateHoles]);

  const clearLines = useCallback(() => {
    let newGrid = [...grid];
    let linesCleared = 0;

    for (let y = ROWS - 1; y >= 0; y--) {
      if (newGrid[y].every(cell => cell === 1)) {
        newGrid.splice(y, 1);
        newGrid.unshift(Array(COLS).fill(0));
        linesCleared++;
        y++;
      }
    }

    if (linesCleared > 0) {
      setGrid(newGrid);
      return true;
    }
    return false;
  }, [grid]);

  const lockShape = useCallback(() => {
    const newGrid = grid.map(row => [...row]);
    let shouldLock = false;

    for (let y = 0; y < currentShape.length; y++) {
      for (let x = 0; x < currentShape[y].length; x++) {
        if (currentShape[y][x]) {
          const newY = shapeY + y;
          if (newY >= 0 && newY < ROWS && shapeX + x >= 0 && shapeX + x < COLS) {
            newGrid[newY][shapeX + x] = 1;
            shouldLock = true;
          }
        }
      }
    }

    if (shouldLock) {
      setGrid(newGrid);
      clearLines();
      spawnNewShape();
      return true;
    }
    return false;
  }, [currentShape, shapeX, shapeY, grid, clearLines]);

  const spawnNewShape = useCallback(() => {
    const newShape = SHAPES[Math.floor(Math.random() * SHAPES.length)];
    const newX = Math.floor(COLS / 2) - Math.floor(newShape[0].length / 2);

    if (collide(newShape, newX, 0)) {
      onGameOver();
      return false;
    }

    setCurrentShape(newShape);
    setShapeX(newX);
    setShapeY(0);
    setGameSpeed(SPEEDS.NORMAL);
    setTargetPosition(null);
    setFastFall(false);
    setMovementQueue([]);
    return true;
  }, [collide, onGameOver]);

  const moveDown = useCallback(() => {
    if (!collide(currentShape, shapeX, shapeY + 1)) {
      setShapeY(y => y + 1);
      return true;
    }
    return false;
  }, [currentShape, shapeX, shapeY, collide]);

  useEffect(() => {
    if (localIsCorrect === true) {
      setAutoArrange(true);
      setGameSpeed(SPEEDS.FAST);
      const bestPos = findBestPosition();
      setTargetPosition(bestPos);
      setFastFall(true);
    } else if (localIsCorrect === false) {
      setAutoArrange(false);
      setGameSpeed(SPEEDS.FALLING);
      setTargetPosition(null);
      setFastFall(true);
    } else {
      setAutoArrange(false);
      setGameSpeed(SPEEDS.NORMAL);
      setTargetPosition(null);
      setFastFall(false);
    }

    // Reset isCorrect state after a short delay
    if (localIsCorrect !== null) {
      window.setTimeout(() => {
        setLocalIsCorrect(null);
      }, 800);
    }
  }, [localIsCorrect, findBestPosition]);

  const executeNextMovement = useCallback(() => {
    if (movementQueue.length === 0) return false;

    const nextMove = movementQueue[0];
    if (nextMove.type === 'rotate') {
      const rotated = rotate(currentShape, nextMove.value || 1);
      if (!collide(rotated, shapeX, shapeY)) {
        setCurrentShape(rotated);
      }
    } else if (nextMove.type === 'move') {
      const newX = shapeX + Math.sign(nextMove.value || 0);
      if (!collide(currentShape, newX, shapeY)) {
        setShapeX(newX);
      }
    }

    setMovementQueue(queue => queue.slice(1));
    return true;
  }, [movementQueue, currentShape, shapeX, shapeY, rotate, collide]);

  const draw = useCallback((ctx: CanvasRenderingContext2D) => {
    ctx.fillStyle = "#FF1C60";
    ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    // Draw grid
    for (let y = 0; y < ROWS; y++) {
      for (let x = 0; x < COLS; x++) {
        if (grid[y][x]) {
          ctx.fillStyle = "#fff";
          ctx.fillRect(x * BLOCK_SIZE, y * BLOCK_SIZE, BLOCK_SIZE - 1, BLOCK_SIZE - 1);
        }
      }
    }

    // Draw current piece
    for (let y = 0; y < currentShape.length; y++) {
      for (let x = 0; x < currentShape[y].length; x++) {
        if (currentShape[y][x]) {
          ctx.fillStyle = "#fff";
          ctx.fillRect(
            (shapeX + x) * BLOCK_SIZE,
            (shapeY + y) * BLOCK_SIZE,
            BLOCK_SIZE - 1,
            BLOCK_SIZE - 1
          );
        }
      }
    }

    // Draw target position
    if (autoArrange && targetPosition) {
      ctx.fillStyle = "rgba(255, 255, 255, 0.2)";
      for (let y = 0; y < targetPosition.shape.length; y++) {
        for (let x = 0; x < targetPosition.shape[y].length; x++) {
          if (targetPosition.shape[y][x]) {
            ctx.fillRect(
              (targetPosition.x + x) * BLOCK_SIZE,
              (targetPosition.y + y) * BLOCK_SIZE,
              BLOCK_SIZE - 1,
              BLOCK_SIZE - 1
            );
          }
        }
      }
    }

    if (gameOver) {
      ctx.fillStyle = "rgba(255, 28, 96, 0.5)";
      ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    }
  }, [grid, currentShape, shapeX, shapeY, autoArrange, targetPosition, gameOver]);

  const update = useCallback((timestamp: number) => {
    if (!lastUpdateRef.current) lastUpdateRef.current = timestamp;
    
    const elapsed = timestamp - lastUpdateRef.current;
    const currentSpeed = fastFall ? SPEEDS.FALLING : gameSpeed;

    if (elapsed > currentSpeed && !gameOver && shouldUpdate) {
      if (autoArrange && targetPosition) {
        if (movementQueue.length > 0) {
          executeNextMovement();
        } else if (shapeY < targetPosition.y) {
          setShapeY(y => y + 1);
        } else {
          lockShape();
        }
      } else if (!moveDown()) {
        lockShape();
      }
      
      lastUpdateRef.current = timestamp;
    }

    const ctx = canvasRef.current?.getContext('2d');
    if (ctx) {
      draw(ctx);
    }

    requestRef.current = requestAnimationFrame(update);
  }, [gameSpeed, gameOver, shouldUpdate, autoArrange, targetPosition, moveDown, lockShape, draw, fastFall, movementQueue, executeNextMovement]);

  useEffect(() => {
    requestRef.current = requestAnimationFrame(update);
    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
    };
  }, [update]);

  useEffect(() => {
    updateCanvasSize();
    window.addEventListener('resize', updateCanvasSize);
    return () => window.removeEventListener('resize', updateCanvasSize);
  }, [updateCanvasSize]);

  return (
    <div ref={containerRef} className="tetris-container flex flex-col items-center justify-center h-full">
      <canvas
        ref={canvasRef}
        width={COLS * BLOCK_SIZE}
        height={ROWS * BLOCK_SIZE}
        className="rounded-[2px] bg-accent shadow-brutal"
      />
    </div>
  );
}
