import { supabase } from '../lib/supabase';

interface Card {
  word: string;
  definition: string;
}

export async function removeDuplicateSets(cards: Card[]): Promise<void> {
  // Get all public sets
  const { data: existingSets, error: setsError } = await supabase
    .from('flashcard_sets')
    .select(`
      id,
      flashcards (
        word,
        definition
      )
    `);

  if (setsError) throw setsError;
  if (!existingSets) return;

  // Find sets with identical content
  const duplicateSets = existingSets.filter(set => {
    const existingCards = set.flashcards as Card[];
    
    if (existingCards.length !== cards.length) return false;

    return existingCards.every(card =>
      cards.some(newCard =>
        newCard.word === card.word &&
        newCard.definition === card.definition
      )
    );
  });

  // Delete duplicate sets
  if (duplicateSets.length > 0) {
    const { error: deleteError } = await supabase
      .from('flashcard_sets')
      .delete()
      .in('id', duplicateSets.map(set => set.id));

    if (deleteError) throw deleteError;
  }
}

export async function getUniqueSetName(baseName: string, cards: Card[]): Promise<string> {
  let counter = 1;
  let uniqueName = baseName;

  while (true) {
    // Check if a set with this name exists
    const { data: existingSets, error: setsError } = await supabase
      .from('flashcard_sets')
      .select(`
        id,
        flashcards (
          word,
          definition
        )
      `)
      .eq('name', uniqueName);

    if (setsError) throw setsError;

    // If no set exists with this name, we can use it
    if (!existingSets || existingSets.length === 0) {
      return uniqueName;
    }

    // Check if any existing set has the same content
    const hasSameContent = existingSets.some(set => {
      const existingCards = set.flashcards as Card[];
      
      if (existingCards.length !== cards.length) return false;

      return existingCards.every(card =>
        cards.some(newCard =>
          newCard.word === card.word &&
          newCard.definition === card.definition
        )
      );
    });

    // If we found a set with same content, we'll delete it later
    if (hasSameContent) {
      return uniqueName;
    }

    // Otherwise, increment counter and try next name
    counter++;
    uniqueName = `${baseName} ${counter}`;
  }
}