import React from 'react';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
}

export const Button: React.FC<ButtonProps> = ({ children, className, ...props }) => {
  return (
    <button
      className="!bg-[#EBFF00] text-black font-bold px-6 py-2
        shadow-[10px_10px_0px_0px_rgba(0,0,0,1)] 
        active:translate-x-[10px] active:translate-y-[10px] active:shadow-none
        transition-[transform,box-shadow] duration-200"
      {...props}
    >
      {children}
    </button>
  );
};