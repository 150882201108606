import { Move } from '../types/cube';

export const generateSolveMoves = (shuffleMoves: Move[]): Move[] => {
  // Reverse the shuffle moves and invert each move's direction
  return shuffleMoves
    .slice()
    .reverse()
    .map(move => ({
      face: move.face,
      direction: move.direction === 'clockwise' ? 'counterclockwise' : 'clockwise'
    }));
};

export const getNextSolveMove = (solveMoves: Move[], currentIndex: number): Move | null => {
  if (currentIndex >= solveMoves.length) {
    return null;
  }
  return solveMoves[currentIndex];
};