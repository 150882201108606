import { useState, useCallback, useRef, forwardRef, useImperativeHandle } from 'react';
import { CubePiece } from './CubePiece';
import { createInitialCubeState, getFaceRotationConfig, rotatePiece } from '../utils/cubeLogic';
import { generateShuffleMoves } from '../utils/shuffleLogic';
import { generateSolveMoves, getNextSolveMove } from '../utils/solveLogic';
import { CubePiece as CubePieceType, Face, Direction, Rotation, Move, CubeOperation } from '../types/cube';
import '../styles/cube.css';

const MOVE_DELAY = 150;

interface RubiksCubeProps {
  isBeastMode?: boolean;
}

export const RubiksCube = forwardRef<
  { handleShuffle: () => void; handleSolve: () => void },
  RubiksCubeProps
>((props, ref) => {
  const { isBeastMode = false } = props;
  const [cubeState, setCubeState] = useState<CubePieceType[]>(createInitialCubeState);
  const [rotation, setRotation] = useState<Rotation>({ x: -30, y: 45, z: 0 });
  const [isRotating, setIsRotating] = useState(false);
  const [operation, setOperation] = useState<CubeOperation>('idle');
  const [solveIndex, setSolveIndex] = useState<number>(0);
  
  const moveQueueRef = useRef<Move[]>([]);
  const shuffleMovesRef = useRef<Move[]>([]);
  const solveMovesRef = useRef<Move[]>([]);
  const timeoutRef = useRef<NodeJS.Timeout>();

  const handleRotation = useCallback((face: Face, direction: Direction) => {
    if (isRotating) return;
    setIsRotating(true);

    const { axis, layer } = getFaceRotationConfig(face);
    const axisIndex = ['x', 'y', 'z'].indexOf(axis);

    setCubeState((prevState) =>
      prevState.map((piece) => {
        if (piece.position[axisIndex] === layer) {
          return rotatePiece(piece, axis, direction);
        }
        return piece;
      })
    );

    setTimeout(() => {
      setIsRotating(false);
    }, MOVE_DELAY);
  }, [isRotating]);

  const executeMoveQueue = useCallback(() => {
    if (moveQueueRef.current.length === 0) {
      setOperation('idle');
      return;
    }

    if (operation === 'paused') {
      return;
    }

    const move = moveQueueRef.current[0];
    moveQueueRef.current = moveQueueRef.current.slice(1);

    handleRotation(move.face, move.direction);
    timeoutRef.current = setTimeout(executeMoveQueue, MOVE_DELAY);
  }, [handleRotation, operation]);

  const handleShuffle = useCallback(() => {
    if (operation !== 'idle') return;
    
    setOperation('shuffling');
    const shuffleMoves = generateShuffleMoves(isBeastMode);
    shuffleMovesRef.current = shuffleMoves;
    moveQueueRef.current = shuffleMoves;
    solveMovesRef.current = generateSolveMoves(shuffleMoves);
    setSolveIndex(0);
    executeMoveQueue();
  }, [executeMoveQueue, operation, isBeastMode]);

  const handleSolve = useCallback(() => {
    if (operation !== 'idle' || isRotating) return;

    const nextMove = getNextSolveMove(solveMovesRef.current, solveIndex);
    if (nextMove) {
      handleRotation(nextMove.face, nextMove.direction);
      setSolveIndex(prev => prev + 1);
    }
  }, [handleRotation, isRotating, solveIndex]);

  useImperativeHandle(ref, () => ({
    handleShuffle,
    handleSolve
  }));

  return (
    <div className="rubiks-cube-container">
      <div className="cube-wrapper">
        <div
          className="rubiks-cube"
          style={{
            transform: `rotateX(${rotation.x}deg) rotateY(${rotation.y}deg) rotateZ(${rotation.z}deg)`,
          }}
        >
          {cubeState.map((piece, index) => (
            <CubePiece key={index} position={piece.position} colors={piece.colors} />
          ))}
        </div>
      </div>
    </div>
  );
});