import React, { useState, useEffect, useRef } from 'react';
import { FlashCard, FlashCardSet } from '../types';
import { X, Trophy, ArrowLeft, RotateCcw } from 'lucide-react';
import TetrisGame from './TetrisGame';
import GameQuestion from './GameQuestion';
import Header from './Header';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';
import { motion, AnimatePresence } from 'framer-motion';

interface VocabularyGameProps {
  cards: FlashCard[];
  currentSet: FlashCardSet | null;
  onExit: () => void;
}

export default function VocabularyGame({ cards, currentSet, onExit }: VocabularyGameProps) {
  const { user } = useAuth();
  const [gameStartTime] = useState(Date.now());
  const [gameQuestions, setGameQuestions] = useState<{
    word: string;
    correctAnswer: string;
    options: string[];
  }[]>([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [score, setScore] = useState(0);
  const [isCorrect, setIsCorrect] = useState<boolean | null>(null);
  const [showWrongAnimation, setShowWrongAnimation] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const [highScore, setHighScore] = useState(() => {
    const saved = localStorage.getItem('vocabulary-game-high-score');
    return saved ? parseInt(saved, 10) : 0;
  });
  const [isProcessingAnswer, setIsProcessingAnswer] = useState(false);

  const successAudioRef = useRef<HTMLAudioElement | null>(null);
  const mistakeAudioRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    // Initialize audio
    successAudioRef.current = new Audio('/Success_Sound.mp3');
    successAudioRef.current.load();
    mistakeAudioRef.current = new Audio('/Mistake_Sound.mp3');
    mistakeAudioRef.current.load();

    // Cleanup
    return () => {
      if (successAudioRef.current) {
        successAudioRef.current.pause();
        successAudioRef.current = null;
      }
      if (mistakeAudioRef.current) {
        mistakeAudioRef.current.pause();
        mistakeAudioRef.current = null;
      }
    };
  }, []);

  const wrongAnimationTimeoutRef = useRef<number>();
  const nextQuestionTimeoutRef = useRef<number>();

  useEffect(() => {
    return () => {
      if (wrongAnimationTimeoutRef.current) {
        clearTimeout(wrongAnimationTimeoutRef.current);
      }
      if (nextQuestionTimeoutRef.current) {
        clearTimeout(nextQuestionTimeoutRef.current);
      }
    };
  }, []);

  const generateQuestions = () => {
    const currentSetCards = currentSet 
      ? cards.filter(card => card.setId === currentSet.id)
      : cards;

    if (currentSetCards.length < 4) {
      alert('Not enough cards for a quiz. Add more vocabulary words!');
      onExit();
      return;
    }

    const setDefinitionsMap = new Map(currentSetCards.map(card => [card.definition, card]));
    const setUniqueDefinitions = Array.from(setDefinitionsMap.values())
      .map(card => card.definition)
      .filter(def => def !== undefined);

    if (setUniqueDefinitions.length < 4) {
      alert('Not enough unique definitions in this set. Please add more vocabulary words with different definitions!');
      onExit();
      return;
    }

    const shuffledCards = [...currentSetCards].sort(() => Math.random() - 0.5);
    
    const questions = shuffledCards.map(card => {
      const otherDefinitions = setUniqueDefinitions.filter(def => def !== card.definition);
      const wrongAnswers = otherDefinitions
        .sort(() => Math.random() - 0.5)
        .slice(0, 3);

      return {
        word: card.word,
        correctAnswer: card.definition,
        options: [card.definition, ...wrongAnswers].sort(() => Math.random() - 0.5)
      };
    });

    setGameQuestions(questions);
    setCurrentQuestionIndex(0);
    setIsCorrect(null);
  };

  useEffect(() => {
    generateQuestions();
  }, [cards, currentSet]);

  useEffect(() => {
    if (score > highScore) {
      setHighScore(score);
      localStorage.setItem('vocabulary-game-high-score', score.toString());
    }
  }, [score, highScore]);

  const handleAnswer = async (selectedAnswer: string) => {
    if (!currentQuestion || gameOver || isProcessingAnswer) return;

    setIsProcessingAnswer(true);
    const correct = selectedAnswer === currentQuestion.correctAnswer;
    const pointsEarned = correct ? 1 : -1;
    const duration = Math.floor((Date.now() - gameStartTime) / 1000);

    // Play appropriate sound
    if (correct) {
      if (successAudioRef.current) {
        successAudioRef.current.currentTime = 0;
        successAudioRef.current.play().catch(console.error);
      }
    } else {
      if (mistakeAudioRef.current) {
        mistakeAudioRef.current.currentTime = 0;
        mistakeAudioRef.current.play().catch(console.error);
      }
    }

    setIsCorrect(correct);
    setScore(prevScore => prevScore + pointsEarned);

    // Save score and update time_spent
    if (user) {
      try {
        // First, save the game score
        const { error: insertError } = await supabase.from('game_scores').insert({
          user_id: user.id,
          game_type: 'vocabulary',
          score: pointsEarned,
          duration: duration,
        });

        if (insertError) throw insertError;

        // Fetch the current time_spent
        const { data: profile, error: fetchError } = await supabase
          .from('profiles')
          .select('time_spent')
          .eq('id', user.id)
          .single();

        if (fetchError) throw fetchError;

        const newTimeSpent = (profile?.time_spent || 0) + duration;

        // Update the time_spent
        const { error: updateError } = await supabase
          .from('profiles')
          .update({ time_spent: newTimeSpent })
          .eq('id', user.id);

        if (updateError) throw updateError;
      } catch (err) {
        console.error('Error saving game score or updating time spent:', err);
      }
    }

    if (!correct) {
      setShowWrongAnimation(true);
      if (wrongAnimationTimeoutRef.current) {
        clearTimeout(wrongAnimationTimeoutRef.current);
      }
      wrongAnimationTimeoutRef.current = window.setTimeout(() => {
        setShowWrongAnimation(false);
      }, 250);
    }

    // Reset isCorrect state after a short delay
    window.setTimeout(() => {
      setIsCorrect(null);
    }, 800);

    if (nextQuestionTimeoutRef.current) {
      clearTimeout(nextQuestionTimeoutRef.current);
    }
    nextQuestionTimeoutRef.current = window.setTimeout(() => {
      // Instead of checking for the last question, cycle through questions indefinitely
      setCurrentQuestionIndex(prev => (prev + 1) % gameQuestions.length);
      setIsProcessingAnswer(false);
    }, 1000);
  };

  const handlePlayAgain = async () => {
    if (gameOver && user) {
      const duration = Math.floor((Date.now() - gameStartTime) / 1000);

      try {
        // Save final game score
        const { error: insertError } = await supabase.from('game_scores').insert({
          user_id: user.id,
          game_type: 'vocabulary',
          score: score,
          duration: duration,
        });

        if (insertError) throw insertError;

        // Fetch the current time_spent
        const { data: profile, error: fetchError } = await supabase
          .from('profiles')
          .select('time_spent')
          .eq('id', user.id)
          .single();

        if (fetchError) throw fetchError;

        const newTimeSpent = (profile?.time_spent || 0) + duration;

        // Update the time_spent
        const { error: updateError } = await supabase
          .from('profiles')
          .update({ time_spent: newTimeSpent })
          .eq('id', user.id);

        if (updateError) throw updateError;
      } catch (err) {
        console.error('Error saving final game score or updating time spent:', err);
      }
    }

    // Reset game state
    setScore(0);
    setGameOver(false);
    setIsCorrect(null);
    setCurrentQuestionIndex(0);
    setIsProcessingAnswer(false);
    generateQuestions();
  };

  const currentQuestion = gameQuestions[currentQuestionIndex];
  if (!currentQuestion) return null;

  return (
    <div className="h-screen bg-primary flex flex-col overflow-hidden">
      <div className="w-full h-full max-w-[800px] mx-auto flex flex-col p-4 gap-4">
        <Header
          showBackButton
          onBack={onExit}
          score={score}
          highScore={highScore}
        />

      {showWrongAnimation && (
        <div className="fixed inset-0 flex items-center justify-center z-50 pointer-events-none">
          <X className="w-64 h-64 text-highlight animate-bounce" strokeWidth={4} />
        </div>
      )}

        <div className="flex-1 flex flex-col min-h-0">
          <div className="flex-1 min-h-0 flex items-center justify-center mb-10">
            <div className="w-full h-full max-h-[calc(100vh-16rem)] aspect-[10/16]">
              <TetrisGame 
                isCorrect={isCorrect}
                onGameOver={() => setGameOver(true)}
                gameOver={gameOver}
                onReset={handlePlayAgain}
              />
            </div>
          </div>

          <div className="w-full">
            <GameQuestion 
              {...currentQuestion}
              isCorrect={isCorrect}
              onAnswer={handleAnswer}
            />
          </div>
        </div>
      </div>

      <AnimatePresence>
        {gameOver && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/50 flex items-center justify-center z-50"
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              transition={{ type: "spring", duration: 0.5 }}
              className="bg-accent p-8 rounded-[2px] shadow-brutal text-center relative overflow-hidden"
            >
              <span className="text-6xl mb-4 block">🏆</span>
              <h3 className="text-3xl font-bold text-white mb-4">Game Over!</h3>
              <p className="text-xl text-white mb-2">Final Score: {score}</p>
              {score === highScore && score > 0 && (
                <motion.p
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 0.2 }}
                  className="text-highlight text-lg mb-4"
                >
                  New High Score! 🎉
                </motion.p>
              )}
              <div className="space-x-4">
                <button 
                  onClick={handlePlayAgain} 
                  className="button-3d flex items-center gap-2 inline-flex"
                >
                  <RotateCcw className="w-5 h-5" />
                  Play Again
                </button>
                <button 
                  onClick={onExit} 
                  className="button-3d flex items-center gap-2 inline-flex"
                >
                  <ArrowLeft className="w-5 h-5" />
                  Exit
                </button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}