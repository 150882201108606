import { Face, Direction, Move } from '../types/cube';

const EASY_MODE_MOVES = 22;
const BEAST_MODE_MOVES = 30;
const FACES: Face[] = ['U', 'D', 'F', 'B', 'L', 'R'];
const DIRECTIONS: Direction[] = ['clockwise', 'counterclockwise'];

export const generateShuffleMoves = (isBeastMode: boolean): Move[] => {
  const moveCount = isBeastMode ? BEAST_MODE_MOVES : EASY_MODE_MOVES;
  const moves: Move[] = [];
  let lastFace: Face | null = null;

  for (let i = 0; i < moveCount; i++) {
    let face: Face;
    do {
      face = FACES[Math.floor(Math.random() * FACES.length)];
    } while (face === lastFace); // Avoid consecutive moves of the same face

    const direction = DIRECTIONS[Math.floor(Math.random() * DIRECTIONS.length)];
    moves.push({ face, direction });
    lastFace = face;
  }

  return moves;
};