import { useCallback, useRef, useState } from 'react';
import Header from './Header';
import { RubiksCube } from './RubiksCube';
import { RetroGrid } from './RetroGrid';
import { WordGame } from './WordGame';
import { ModeToggle } from './ModeToggle';

interface CubeyGameProps {
  onExit: () => void;
}
  
export default function CubeyGame({ onExit }: CubeyGameProps) {
  const [score, setScore] = useState(0);
  const [isBeastMode, setIsBeastMode] = useState(false);
  const cubeRef = useRef<{ handleShuffle: () => void; handleSolve: () => void; }>(null);

  const handleWordComplete = useCallback((newScore: number) => {
    setScore(newScore);
    if (newScore > 0) {
      cubeRef.current?.handleSolve();
    }
  }, []);

  const handleGameStart = useCallback(() => {
    cubeRef.current?.handleShuffle();
  }, [isBeastMode]);

  const handleModeToggle = useCallback(() => {
    setIsBeastMode(prev => {
      const newMode = !prev;
      console.log('Beast Mode is now:', newMode);
      return newMode;
    });
  }, []);

  const totalWords = parseInt(localStorage.getItem('totalWords') || '0', 10);

  return (
    <div className="min-h-screen bg-primary flex flex-col">
      <RetroGrid />
      <div className="w-full h-full max-w-[800px] mx-auto flex flex-col p-4 gap-8">
        <Header
          showBackButton
          onBack={onExit}
        />
        <div className="flex-1 flex items-center justify-center">
          {/* <div className="text-center">
            <h1 className="text-6xl font-bold text-highlight mb-8">Coming Soon!</h1>
            <p className="text-2xl text-white">Get ready for an exciting new way to learn</p>
          </div> */}
        </div>
        <div className="flex flex-col items-center pt-12">
          <div className="mb-8 text-center">
            <div className="text-white text-4xl font-bold mb-2">Score</div>
            <div className="text-[#EBFF00] text-8xl font-bold" id="score">{score}</div>
          </div>
          <ModeToggle isBeastMode={isBeastMode} onToggle={handleModeToggle} />
          <div className="mt-64">
            <RubiksCube ref={cubeRef} isBeastMode={isBeastMode} />
            <div className="mt-8">
              <WordGame 
                onWordComplete={handleWordComplete} 
                onGameStart={handleGameStart} 
                totalWords={totalWords}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}