import React, { useState, useCallback, useEffect, useRef } from 'react';
import { GameState, Difficulty } from '../types/game';
import { getRandomWord } from '../utils/wordUtils';
import { Button } from './Button';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';

interface WordGameProps {
  onWordComplete: (newScore: number) => void;
  onGameStart: () => void;
  totalWords: number;
}

export const WordGame: React.FC<WordGameProps> = ({ onWordComplete, onGameStart, totalWords }) => {
  const { user } = useAuth();
  const [gameState, setGameState] = useState<GameState>({
    currentWord: null,
    input: '',
    error: false,
    showAnswer: false,
    showHint: false,
    score: 0,
    difficulty: 'medium',
    isShuffled: false
  });
  const [startTime, setStartTime] = useState<number | null>(null);
  const [usedWords, setUsedWords] = useState<string[]>([]);
  const [localTotalWords, setLocalTotalWords] = useState<number>(totalWords);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const fetchWord = async () => {
      const word = await getRandomWord();
      if (word) {
        setGameState(prev => ({ ...prev, currentWord: word }));
        setStartTime(Date.now());
      }
    };

    if (!gameState.isShuffled) {
      onGameStart();
      fetchWord();
      setGameState(prev => ({ ...prev, isShuffled: true }));
    }
  }, [onGameStart, gameState.isShuffled]);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  useEffect(() => {
    const storedTotalWords = localStorage.getItem('totalWords');
    if (storedTotalWords) {
      const parsedTotalWords = parseInt(storedTotalWords, 10);
      console.log('Total words retrieved from local storage:', parsedTotalWords);
      setLocalTotalWords(parsedTotalWords);
    }
  }, []);

  const updateScoreInDatabase = async (newScore: number, duration: number) => {
    if (user) {
      try {
        console.log('Attempting to update score for user:', user.id);
        console.log('Duration to answer (ms):', duration);
        const { data, error } = await supabase.from('game_scores').insert({
          user_id: user.id,
          game_type: 'word',
          score: newScore,
          duration: duration,
          created_at: new Date().toISOString(),
        }).select();

        if (error) {
          console.error('Error inserting score into database:', error.message);
        } else {
          console.log('Score successfully added to database:', data);
        }
      } catch (err) {
        console.error('Unexpected error updating game score:', err);
      }
    } else {
      console.warn('User not authenticated, skipping database update.');
    }
  };

  const handleSubmitAnswer = () => {
    const duration = startTime ? Date.now() - startTime : 0;
    if (gameState.currentWord) {
      const wordMatch = gameState.input.toLowerCase() === gameState.currentWord.word.toLowerCase();
      const definitionMatch = gameState.input.toLowerCase() === gameState.currentWord.definition.toLowerCase();
      const isCorrect = wordMatch || definitionMatch;
      console.log('User Input:', gameState.input);
      console.log('Correct Word:', gameState.currentWord.word);
      console.log('Correct Definition:', gameState.currentWord.definition);
      console.log('Is Correct:', isCorrect);
      console.log('Answer Duration (ms):', duration);
      
      if (isCorrect) {
        const newScore = gameState.score + 1;
        console.log('Correct answer! New Score:', newScore);
        setGameState(prev => ({
          ...prev,
          showHint: false,
          score: newScore,
          currentWord: null,
          input: ''
        }));
        updateScoreInDatabase(newScore, duration);
        onWordComplete(newScore);
      } else {
        const newScore = gameState.score - 1;
        console.log('Wrong answer! New Score:', newScore);
        setGameState(prev => ({
          ...prev,
          score: newScore,
          input: '',
          error: true,
          showAnswer: true
        }));
        updateScoreInDatabase(newScore, duration);
        onWordComplete(newScore);
      }
      setStartTime(Date.now());
    }
  };

  const handleKeyPress = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleNext();
    }
  }, [gameState.input, gameState.currentWord, onWordComplete, gameState.score, gameState.showAnswer, startTime]);

  useEffect(() => {
    window.addEventListener('keypress', handleKeyPress);
    return () => window.removeEventListener('keypress', handleKeyPress);
  }, [handleKeyPress]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGameState(prev => ({ ...prev, input: event.target.value }));
  };

  const fetchNewWord = async () => {
    let newWord;
    do {
      newWord = await getRandomWord();
    } while (newWord && usedWords.includes(newWord.word) && usedWords.length < localTotalWords);

    if (newWord) {
      console.log('New word fetched:', newWord.word);
      setGameState(prev => ({
        ...prev,
        showHint: false,
        showAnswer: false,
        error: false,
        currentWord: newWord,
        input: ''
      }));
      setStartTime(Date.now());
      setUsedWords(prev => [...prev, newWord.word]);

      // Reset used words if all words have been used
      if (usedWords.length + 1 === localTotalWords) {
        setUsedWords([]);
      }
    }
  };

  const handleNext = () => {
    handleSubmitAnswer();
    fetchNewWord();
  };

  const handleHint = () => {
    console.log('Hint requested. Showing partial word.');
    setGameState(prev => ({
      ...prev,
      showHint: true
    }));
  };

  const getDisplayWord = () => {
    const word = gameState.currentWord?.word.toLowerCase();
    const input = gameState.input.toLowerCase();
    
    if (gameState.showAnswer) {
      return word?.split('').join(' ');
    }
    
    return word?.split('').map((_, index) => {
      if (gameState.showHint && index < Math.floor(word?.length / 2)) {
        return word[index];
      } else if (input[index]) {
        return input[index];
      } else {
        return '_';
      }
    }).join(' ');
  };

  return (
    <div className="word-game text-center">
      <div className="text-4xl font-mono mb-4 tracking-wider text-white">
        {getDisplayWord()}
      </div>
      {gameState.showAnswer && (
        <div className="text-red-400 text-lg mb-4">
          Incorrect! The answer was: {gameState.currentWord?.word}
        </div>
      )}
      <div className="text-white text-lg mb-8 max-w-md mx-auto">
        {gameState.currentWord?.definition}
      </div>
      <div className="flex items-center justify-center gap-4">
        <Button
          onClick={handleHint}
        >
          Hint
        </Button>
        <input
          ref={inputRef}
          type="text"
          value={gameState.input}
          onChange={handleInputChange}
          className={`bg-white/10 text-white border ${
            gameState.error ? 'border-red-500 animate-shake' : 'border-white/20'
          } rounded-lg px-4 py-2 w-64 text-center focus:outline-none focus:border-white/40 transition-colors`}
          placeholder="Type your answer..."
          autoFocus
        />
        <Button
          onClick={handleNext}
        >
          Next
        </Button>
      </div>
    </div>
  );
};