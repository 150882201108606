import React from 'react';
import { Logo } from './Logo';
import UserMenu from './UserMenu';

interface HeaderProps {
  showBackButton?: boolean;
  onBack?: () => void;
  showUserMenu?: boolean;
  score?: number;
  highScore?: number;
}

export default function Header({ showBackButton, onBack, showUserMenu = true, score, highScore }: HeaderProps) {
  return (
    <div className="flex justify-between items-center w-full">
      <div className="flex items-center gap-4">
        {showBackButton && (
          <button
            onClick={onBack}
            className="button-3d text-sm md:text-base px-3 py-1 md:px-8 md:py-2 mr-4 "
          >
            ← Back
          </button>
        )}
        <Logo />
      </div>

      <div className="flex items-center gap-4">
        {typeof score !== 'undefined' && (
          <div className="text-right">
            <p className="text-white text-xl">Score</p>
            <p className="text-4xl font-bold text-highlight">{score}</p>
            {typeof highScore !== 'undefined' && (
              <p className="text-white text-sm mt-1">High Score: {highScore}</p>
            )}
          </div>
        )}
        {showUserMenu && <UserMenu />}
      </div>
  </div>
);
}