import React from 'react';

interface ModeToggleProps {
  isBeastMode: boolean;
  onToggle: () => void;
}

export const ModeToggle: React.FC<ModeToggleProps> = ({ isBeastMode, onToggle }) => {
  return (
    <div className="flex items-center justify-center gap-3 mb-8">
      <span className="text-white font-bold">Easy Mode</span>
      <button
        onClick={onToggle}
        className="w-16 h-8 rounded-full relative bg-white/20 transition-colors duration-200"
        aria-pressed={isBeastMode}
      >
        <div
          className={`absolute top-1 w-6 h-6 rounded-full transition-all duration-200
            ${isBeastMode ? 'right-1 bg-[#EBFF00]' : 'left-1 bg-white'}`}
        />
      </button>
      <span className="text-white font-bold">Beast Mode</span>
    </div>
  );
};