import { CubePiece, Face, Direction } from '../types/cube';
import { COLORS } from '../constants/colors';

export const createInitialCubeState = (): CubePiece[] => {
  const initialState: CubePiece[] = [];
  for (let x = -1; x <= 1; x++) {
    for (let y = -1; y <= 1; y++) {
      for (let z = -1; z <= 1; z++) {
        initialState.push({
          position: [x, y, z],
          colors: [
            y === -1 ? COLORS[0] : COLORS[5],
            x === 1 ? COLORS[1] : COLORS[3],
            z === -1 ? COLORS[2] : COLORS[4],
            x === -1 ? COLORS[3] : COLORS[1],
            z === 1 ? COLORS[4] : COLORS[2],
            y === 1 ? COLORS[5] : COLORS[0],
          ]
        });
      }
    }
  }
  return initialState;
};

export const getFaceRotationConfig = (face: Face) => {
  const config = {
    U: { axis: 'y', layer: -1 },
    D: { axis: 'y', layer: 1 },
    F: { axis: 'z', layer: -1 },
    B: { axis: 'z', layer: 1 },
    L: { axis: 'x', layer: -1 },
    R: { axis: 'x', layer: 1 },
  };
  return config[face];
};

export const rotatePiece = (
  piece: CubePiece,
  axis: string,
  direction: Direction
): CubePiece => {
  const newPosition = [...piece.position] as [number, number, number];
  const newColors = [...piece.colors];

  if (axis === 'x') {
    if (direction === 'clockwise') {
      [newPosition[1], newPosition[2]] = [newPosition[2], -newPosition[1]];
      [newColors[0], newColors[2], newColors[5], newColors[4]] = 
        [newColors[4], newColors[0], newColors[2], newColors[5]];
    } else {
      [newPosition[1], newPosition[2]] = [-newPosition[2], newPosition[1]];
      [newColors[0], newColors[2], newColors[5], newColors[4]] = 
        [newColors[2], newColors[5], newColors[4], newColors[0]];
    }
  } else if (axis === 'y') {
    if (direction === 'clockwise') {
      [newPosition[0], newPosition[2]] = [-newPosition[2], newPosition[0]];
      [newColors[1], newColors[2], newColors[3], newColors[4]] = 
        [newColors[4], newColors[1], newColors[2], newColors[3]];
    } else {
      [newPosition[0], newPosition[2]] = [newPosition[2], -newPosition[0]];
      [newColors[1], newColors[2], newColors[3], newColors[4]] = 
        [newColors[2], newColors[3], newColors[4], newColors[1]];
    }
  } else if (axis === 'z') {
    if (direction === 'clockwise') {
      [newPosition[0], newPosition[1]] = [newPosition[1], -newPosition[0]];
      [newColors[0], newColors[1], newColors[5], newColors[3]] = 
        [newColors[3], newColors[0], newColors[1], newColors[5]];
    } else {
      [newPosition[0], newPosition[1]] = [-newPosition[1], newPosition[0]];
      [newColors[0], newColors[1], newColors[5], newColors[3]] = 
        [newColors[1], newColors[5], newColors[3], newColors[0]];
    }
  }

  return { position: newPosition, colors: newColors };
};