import React, { memo, useRef, useEffect } from 'react';
import { Search } from 'lucide-react';

interface SearchInputProps {
  value: string;
  onChange: (value: string) => void;
  onSearch: () => void;
  placeholder?: string;
}

function SearchInput({ value, onChange, onSearch, placeholder = 'Search...' }: SearchInputProps) {
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [value]);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSearch();
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSearch();
  };

  return (
    <form onSubmit={handleSubmit} className="flex gap-2">
      <div className="relative flex-1">
        <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-black/50" />
        <input
          ref={inputRef}
          type="text"
          inputMode="text"
          enterKeyHint="search"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          className="w-full pl-10 pr-4 py-2 rounded-[2px] bg-white text-black placeholder-black/50 focus:outline-none focus:ring-2 focus:ring-highlight transition-shadow"
        />
      </div>
      <button
        type="submit"
        className="button-3d py-2 w-[120px] flex items-center justify-center gap-0"
      >
        <Search className="w-5 h-5" />
        Search
      </button>
    </form>
  );
}

export default memo(SearchInput);