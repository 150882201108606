import React, { useCallback, useState } from 'react';
import Papa from 'papaparse';
import { FlashCard } from '../types';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';
import { Loader2 } from 'lucide-react';
import { removeDuplicateSets, getUniqueSetName } from '../utils/setUtils';

interface FileUploadProps {
  onCardsUpload: (cards: FlashCard[], fileName: string) => void;
}

export default function FileUpload({ onCardsUpload }: FileUploadProps) {
  const { user } = useAuth();
  const [uploading, setUploading] = useState(false);

  const handleFileUpload = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file || !user) return;

    setUploading(true);

    Papa.parse<string[]>(file, {
      complete: async (results) => {
        try {
          const cards = results.data
            .filter((row) => row.length >= 2 && row[0] && row[1])
            .map(row => ({
              word: row[0].trim(),
              definition: row[1].trim()
            }));

          if (cards.length === 0) {
            throw new Error('No valid cards found in the CSV file. Please check the format.');
          }

          // Check for duplicate sets
          await removeDuplicateSets(cards);
          
          // Get unique name for the set
          const baseName = file.name.replace('.csv', '');
          const uniqueName = await getUniqueSetName(baseName, cards);

          // First create the set
          const { data: setData, error: setError } = await supabase
            .from('flashcard_sets')
            .insert({
              name: uniqueName,
              created_by: user.id,
              is_public: true
            })
            .select()
            .single();

          if (setError) throw setError;

          // Then create the cards
          const flashCards: FlashCard[] = cards.map(card => ({
              id: crypto.randomUUID(),
              setId: setData.id,
              word: card.word,
              definition: card.definition,
              easeFactor: 2.5,
              interval: 0,
              repetitions: 0,
              nextReview: new Date()
            }));


          // Insert cards into database
          const { error: cardsError } = await supabase
            .from('flashcards')
            .insert(flashCards.map(card => ({
              set_id: card.setId,
              word: card.word,
              definition: card.definition
            })));

          if (cardsError) throw cardsError;

          // Reset the input and notify parent
          event.target.value = '';
          onCardsUpload(cards, file.name.replace('.csv', ''));

          // Force a hard reload after successful upload
          window.location.href = window.location.href;
        } catch (error) {
          console.error('Error uploading cards:', error);
          alert(error instanceof Error ? error.message : 'Failed to upload cards. Please try again.');
          event.target.value = '';
        } finally {
          setUploading(false);
        }
      },
      header: false,
      skipEmptyLines: true,
      transform: (value) => value.trim(),
    });
  }, [onCardsUpload, user]);

  const handleDragOver = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDrop = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    
    const file = e.dataTransfer.files[0];
    if (file && file.type === 'text/csv') {
      const input = document.getElementById('file-upload') as HTMLInputElement;
      if (input) {
        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(file);
        input.files = dataTransfer.files;
        input.dispatchEvent(new Event('change', { bubbles: true }));
      }
    } else {
      alert('Please upload a CSV file');
    }
  }, []);

  return (
    <div className="w-full">
      <div
        className={`drop-zone text-center relative ${uploading ? 'opacity-50' : ''}`}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        {uploading && (
          <div className="absolute inset-0 flex items-center justify-center bg-black/20">
            <Loader2 className="w-8 h-8 animate-spin text-white" />
          </div>
        )}
        <p className="text-xl mb-4">
          Drop your file here or click the <span className="font-bold">Upload</span> button above.
        </p>
        <p className="text-white/80">
          CSV file with words in the first column, definitions in the second column.
        </p>
        <input
          id="file-upload"
          type="file"
          accept=".csv,text/csv"
          className="hidden"
          onChange={handleFileUpload}
          disabled={uploading}
        />
      </div>
    </div>
  );
}