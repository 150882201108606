import React from 'react';
import { CubePiece as CubePieceType } from '../types/cube';

interface CubePieceProps {
  position: CubePieceType['position'];
  colors: CubePieceType['colors'];
}

export const CubePiece: React.FC<CubePieceProps> = ({ position, colors }) => (
  <div
    className="cube-piece"
    style={{
      transform: `translate3d(${position[0] * 100}px, ${position[1] * 100}px, ${
        position[2] * 100
      }px)`,
    }}
  >
    {colors.map((color, index) => (
      <div
        key={index}
        className={`face face-${index}`}
        style={{ backgroundColor: color }}
      />
    ))}
  </div>
);